import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'
import { Container } from '@bluheadless/ui/src/organisms/hero/hero-banner.styled'

const shouldForwardProp = (prop) =>
	![
		'autoplayAlways',
		'hide',
		'horizontalAlign',
		'light',
		'originalHeight',
		'originalWidth',
		'playIconHorizontalAlign',
		'playIconVerticalAlign',
		'playing',
		'startOnEveryInView',
		'stopOnEveryOutView',
		'template',
		'thresholdInView',
		'verticalAlign',
		'additionalContent',
		'subtitleProps',
		'hasVideo',
		'aboveTheFold',
	].includes(prop)

export const textAlign = (align) => {
	switch (align) {
		case 'flex-start':
			return 'left'
		case 'flex-end':
			return 'left'
		case 'center':
			return 'center'
	}
}

const horizontalSpacing = (align) => {
	switch (align) {
		case 'flex-start':
			return 'margin-right: auto; padding-right: 8.75%;'
		case 'flex-end':
			return 'margin-left: auto; padding-left: 8.75%;'
		case 'center':
			return 'margin: 0 auto;'
	}
}

const OverlayContainer = styled('div')`
	position: absolute;
	z-index: 2;
	margin: var(--spacing-5) var(--spacing-2);
	left: 0;
	right: 0;
	max-width: 100%;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin: var(--spacing-5);
	}
`

const Root = styled(Grid, { shouldForwardProp })`
	align-items: ${({ verticalAlign }) => verticalAlign};
	/* justify-content: ${({ horizontalAlign }) => horizontalAlign}; */
	position: relative;
	${OverlayContainer} {
		text-align: ${({ horizontalAlign }) => textAlign(horizontalAlign)};
	}
	${Container} {
		${({ theme }) => theme.breakpoints.up('md')} {
			${({ horizontalAlign }) => horizontalSpacing(horizontalAlign)}
		}
	}
	&.top {
		${OverlayContainer} {
			top: 0;
			bottom: auto;
		}
	}
	&.bottom {
		${OverlayContainer} {
			bottom: 0;
			top: auto;
		}
	}
	&.mobile-bottom {
		${({ theme }) => theme.breakpoints.down('sm')} {
			${OverlayContainer} {
				bottom: 0;
				top: auto;
			}
		}
	}
`

export { OverlayContainer, Root }
