import Typography from '../../atoms/typography'
import BHLink from '../../molecules/link'
import MuiGrid from '@mui/material/Grid'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo'].includes(prop)
}

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	gap: var(--spacing-3);
	width: 100%;
	min-width: fit-content;
	${({ theme }) => theme.breakpoints.up('md')} {
		width: auto;
		max-width: 50%;
	}
`

const Title = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.white};
	line-height: 1;
`

const Subtitle = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.white};
	line-height: 1;
`

const Description = styled('div')`
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	color: ${({ theme }) => theme.palette.common.white};
	line-height: 1;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	&:not(:only-child) {
		margin-top: var(--spacing-3);
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
		margin-top: 0 !important;
	}
`

const Link = styled(BHLink)`
	width: 100%;
`

const Button = styled(BHButton)`
	width: max-content;
	${({ size, variant, theme }) =>
		variant === 'textLink' &&
		(size === 'small'
			? `font-size: ${theme.typography.button.fontSize}; line-height: 1;`
			: `font-size: ${theme.typography.body4.fontSize}; line-height: ${theme.typography.body4.lineHeight};`)}
`

export { Container, Title, Subtitle, Description, CtaContainer, Link, Button }
